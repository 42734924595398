<div class="search-results">
  <div *ngIf="searchAreas.length; then searchResults; else notFound"></div>
</div>

<ng-template #searchResults>
  <h2 class="visually-hidden">Search Results</h2>
  <div class="search-area" *ngFor="let area of searchAreas">
    <h3>{{area.name}} ({{area.pages.length + area.priorityPages.length}})</h3>
    <ul class="priority-pages" >
      <li class="search-page" *ngFor="let page of area.priorityPages">
        <a class="search-result-item" href="{{ page.path }}" (click)="onResultSelected(page, $event)">
          <span class="symbol {{page.type}}" *ngIf="area.name === 'api'"></span>{{ page.title }}
        </a>
      </li>
    </ul>
    <ul>
      <li class="search-page" *ngFor="let page of area.pages">
        <a class="search-result-item" href="{{ page.path }}" (click)="onResultSelected(page, $event)">
          <span class="symbol {{page.type}}" *ngIf="area.name === 'api'"></span>{{ page.title }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #notFound>
  <p>{{notFoundMessage}}</p>
</ng-template>
